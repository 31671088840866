import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import { find } from "lodash";

import Loading from "components/Loading";

import Emitter from "helpers/emitter_helper";

class Tramite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: null,
      scheduleName: null,
      validated: false
    };
  }

  isValidated() {
    return this.state.validated;
  }

  componentDidMount() {
    Emitter.on('TRAMITE_FROM_URL', (value) => {
      if (value) {
        const gschedule = find(this.props.groupedSchedules, { name: value });
        const schedule = find(this.props.schedules, { id: gschedule.id });

        this.setState({ schedule: gschedule.id, scheduleName: schedule.name, validated: true });

        Emitter.emit('SET_SCHEDULE', value);
        Emitter.emit('BREADCRUMB_SCHEDULE', value);

        Emitter.emit('NEXT_BUTTON_CLICK', true);
      }
    });
  }

  componentWillUnmount() {
    Emitter.off('TRAMITE_FROM_URL');
  }

  setSchedule(event) {
    if (this.state.schedule && this.state.schedule != event.target.value) {
      Emitter.emit('RESET', true);
    }
    
    // Seteamos un trámite temporario, ya que agrupamos por nombre, y el trámite definitivo
    // se va a definir en base a la sucursal 
    let id = parseInt(event.target.value);

    let schedule = find(this.props.schedules, { id: id });

    this.setState({ schedule: id, scheduleName: schedule.name, validated: true });

    Emitter.emit('SET_SCHEDULE', schedule.name);
    Emitter.emit('BREADCRUMB_SCHEDULE', schedule.name);
  }

  render() {
    return (
      <React.Fragment>
        <Row className="justify-content-center">
          {this.props.loading && (
            <Loading />
          )}
        </Row>
        <Row>
          {this.props.groupedSchedules.map((schedule) => (
            <Col key={schedule.id} md={6} xs={12}>
              <div className="custom-radio mr-3">
                <input id={'schedule-' + schedule.id} name="schedules" type="radio" value={schedule.id} checked={this.state.schedule === schedule.id} onChange={this.setSchedule.bind(this)} />
                <label htmlFor={'schedule-' + schedule.id}>{schedule.name}</label>
              </div>
            </Col>
          )
          )}
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading, schedules, groupedSchedules, error } = state.Home;
  return {
    loading, schedules, groupedSchedules, error
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(Tramite);

