import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import { map, find, filter } from "lodash";

import moment from "moment";

import { getBranches } from "store/actions";

import { getAllSchedulesByDateAndBranch } from "services/appointments";

import Emitter from "helpers/emitter_helper";

class SucursalAlt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: null,
      scheduleName: props.wizardData['Tipo de análisis'].scheduleName,
      branches: this.props.branches,
      branch: null,
      zonedStartDate: null,
      validated: false
    };
  }

  componentDidMount() {
    Emitter.on('DATE_HOUR_SELECTED', (zonedStartDate) => {
      let filtered = this.props.datesByBranch.filter((d) => {
        return d.zonedStartDate == zonedStartDate;
      });

      let branches = this.props.branches.filter((b) => {
        return map(filtered, 'branchId').includes(b.id);
      });

      this.setState({ branch: null, branches, zonedStartDate });
      Emitter.emit('BREADCRUMB_BRANCH', '');
    });

    Emitter.on('SET_SCHEDULE', (schedule) => {
      this.setState({ scheduleName: schedule });
    });
  }

  componentWillUnmount() {
    Emitter.off('DATE_HOUR_SELECTED');
    Emitter.off('SET_SCHEDULE');
  }

  isValidated() {
    return this.state.validated;
  }

  async setBranch(event) {
    let id = parseInt(event.target.value);

    let branch = find(this.props.branches, { id: id });
    Emitter.emit('BREADCRUMB_BRANCH', branch.name);

    this.setState({ branch: id });

    // Una vez elegida la sucursal, tenemos que loopear para saber que trámite elegimos realmente
    let schedule = {
      id: null
    };
    let schedules = filter(this.props.groupedSchedules, (s) => {
      return s.name == this.state.scheduleName;
    });

    for (var i = schedules.length - 1; i >= 0; i--) {
      let r = await getAllSchedulesByDateAndBranch(schedules[i].id, id, moment(this.state.zonedStartDate).format('YYYY-MM-DDTHH:mm:ssZZ'));

      if(r.slots && r.slots.length) {
        schedule.id = schedules[i].id;

        break;
      }
    }

    this.setState({ schedule: schedule.id, validated: true });
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          {this.state.branches.map((branch) => (
            <Col key={branch.id} md={6} xs={12}>
              <div className="custom-radio">
                <input id={'branch-' + branch.id} name="branches" type="radio" value={branch.id} checked={this.state.branch === branch.id} onChange={this.setBranch.bind(this)} />
                <label htmlFor={'branch-' + branch.id}>{branch.name}</label>
              </div>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading, schedules, groupedSchedules, branches, datesByBranch, error } = state.Home;
  return {
    loading, schedules, groupedSchedules, branches, datesByBranch, error
  };
}

export default connect(mapStateToProps, {
  getBranches
}, null, { forwardRef: true })(SucursalAlt);

