import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import Emitter from "helpers/emitter_helper";

class Disponibilidad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      schedule: null,
      validated: false
    };
  }

  componentDidMount() {
    Emitter.on('RESET', (value) => {
      if (value) {
        this.setState({ type: null, validated: false });
      }
    });

    Emitter.on('SET_SCHEDULE', (value) => {
      if (value) {
        this.setState({ schedule: value });
      }
    });
  }

  componentWillUnmount() {
    Emitter.off('RESET');
    Emitter.off('SET_SCHEDULE');
  }

  isValidated() {
    return this.state.validated;
  }

  setType(event) {
    if (this.state.type && this.state.type != event.target.value) {
      Emitter.emit('RESET', true);
    }

    this.setState({ type: parseInt(event.target.value), validated: true });

    Emitter.emit('TYPE_UPDATED', event.target.value);

    setTimeout(() => {
      Emitter.emit('SCHEDULE_SELECTED', this.state.schedule);
    }, 500);
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col md={6} xs={12}>
            <div className="custom-radio">
              <input id="location" name="type" type="radio" checked={this.state.type == 1} value="1" onChange={this.setType.bind(this)} />
              <label htmlFor="location">Por sede</label>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="custom-radio">
              <input id="date" name="type" type="radio" checked={this.state.type == 2} value="2" onChange={this.setType.bind(this)} />
              <label htmlFor="date">Por fecha</label>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(Disponibilidad);

