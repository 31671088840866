export const SCHEDULES_GET = "SCHEDULES_GET";
export const SCHEDULES_GET_SUCCESS = "SCHEDULES_GET_SUCCESS";
export const BRANCHES_GET = "BRANCHES_GET";
export const BRANCHES_GET_SUCCESS = "BRANCHES_GET_SUCCESS";
export const DATES_GET = "DATES_GET";
export const DATES_GET_SUCCESS = "DATES_GET_SUCCESS";
export const DATES_GET_BY_BRANCH = "DATES_GET_BY_BRANCH";
export const DATES_GET_BY_BRANCH_SUCCESS = "DATES_GET_BY_BRANCH_SUCCESS";
export const CREATE_APPOINTMENT = "CREATE_APPOINTMENT";
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const API_ERROR = "API_ERROR";
